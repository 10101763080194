var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mismatchedStateReport"},[(_vm.loading)?_c('LoadingPlaceholder',{staticClass:"my-4"}):[_c('p',[_vm._v("Zabbix State: Only showing triggers with severity of "),_c('span',{staticClass:"gold"},[_vm._v("high")]),_vm._v(" or "),_c('span',{staticClass:"red"},[_vm._v("disaster")]),_vm._v(" that are older than two hours.")]),_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.mismatches),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.mismatches),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"nameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"nameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a name"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('nameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"jiraFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"jiraSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.jiraStatusList),function(status,key){return _c('option',{key:("deviceSearch-jiraFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, status, 'jiraSelect')}},[_vm._v(" "+_vm._s(status)+" ")])})],2),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('jiraSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"zabbixFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"zabbixSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.zabbixStatusList),function(status,key){return _c('option',{key:("deviceSearch-zabbixSelectOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, status, 'zabbixSelect')}},[_vm._v(" "+_vm._s(status)+" ")])})],2),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('zabbixSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"linkTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId))}},[_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])])],1)]}},{key:"jiraStatusTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{class:_vm.computedStatus(_vm.kgm_getNestedValue(props.field, props.dataItem)).color},[_vm._v(_vm._s(_vm.computedStatus(_vm.kgm_getNestedValue(props.field, props.dataItem)).status))])])]}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('button',{staticClass:"btn btn-primary btn-block btn-sm",on:{"click":function($event){return _vm.$eventBus.$emit('mismatchedStateReport_openJiraIssue', props.dataItem.installationId)}}},[_c('span',[_vm._v("View Jira Issue")])])])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }