<template>
  <div class="mismatchedStateReport">
    <LoadingPlaceholder
      v-if="loading"
      class="my-4"
    />
    <template v-else>
      <p>Zabbix State: Only showing triggers with severity of <span class="gold">high</span> or <span class="red">disaster</span> that are older than two hours.</p>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(mismatches)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(mismatches)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="jiraFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="jiraSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(status, key) in jiraStatusList"
              :key="`deviceSearch-jiraFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, status, 'jiraSelect')"
            >
              {{ status }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('jiraSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="zabbixFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="zabbixSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(status, key) in zabbixStatusList"
              :key="`deviceSearch-zabbixSelectOption-${key}`"
              :selected="kgm_selectDefaultOption(props, status, 'zabbixSelect')"
            >
              {{ status }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('zabbixSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="linkTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <template
          slot="jiraStatusTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span :class="computedStatus(kgm_getNestedValue(props.field, props.dataItem)).color">{{ computedStatus(kgm_getNestedValue(props.field, props.dataItem)).status }}</span>
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <button
              class="btn btn-primary btn-block btn-sm"
              @click="$eventBus.$emit('mismatchedStateReport_openJiraIssue', props.dataItem.installationId)"
            >
              <span>View Jira Issue</span>
            </button>
          </td>
        </template>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { jiraStatusMixin } from '@/mixins/jiraStatusMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';

export default {
  name: "MismatchedStateReport",
  mixins: [
    jiraStatusMixin,
    urlParseMixin,
    kendoGridMixin
  ],
  data () {
    return {
      mismatches: null,
      loading: true,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "installationName",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          "field": "installationName",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Name',
          cell: 'linkTemplate',
          filterCell: "nameFilter"
        },
        {
          field: 'jiraState',
          filterable: true,
          filter: 'text',
          title: 'Jira State',
          width:'175px',
          filterCell: 'jiraFilter',
          cell: 'jiraStatusTemplate',
          hideOn: ["smDown"]
        },
        {
          field: 'zabbixState',
          filterable: true,
          filter: 'text',
          title: 'Zabbix State',
          width:'175px',
          filterCell: 'zabbixFilter',
          hideOn: ["mdDown"]
        },
        {
          filterable: false,
          title: 'Options',
          width:'150px',
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl',
    ]),
    zabbixStatusList: function () {
      if (!this.mismatches) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let zabbixStatusList = Array.from(new Set(this.mismatches.map(o => o.zabbixState)));
      //Remove null values
      zabbixStatusList = zabbixStatusList.filter((e) => {
        return e;
      });
      return zabbixStatusList.sort();
    },
    jiraStatusList: function () {
      if (!this.mismatches) {
        return [ "" ];
      }
      let jiraStatusList = Array.from(new Set(this.mismatches.map(o => o.jiraState)));
      jiraStatusList = jiraStatusList.filter((e) => {
        return e;
      });
      return jiraStatusList.sort();
    },
  },
  created () {
    this.getAllMismatchedStates();
    this.$eventBus.$on('mismatchedStateReport_openJiraIssue', this.openJiraIssue);
  },
  beforeDestroy () {
    this.$eventBus.$off('mismatchedStateReport_openJiraIssue', this.openJiraIssue);
  },
  methods: {
    openJiraIssue (issue) {
      window.open(this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ issue }`), '_blank');
    },
    getAllMismatchedStates () {
      this.loading = true;
      this.axios.get(`/MonitoringSystem/GetAllMismatchedStates`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.mismatches = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    computedStatus (status) {
      if (!status) {
        return this.jiraStatus_statusToObject("unknown");
      }
      if (status.length === 0) {
        return this.jiraStatus_statusToObject("unknown");
      }
      return this.jiraStatus_statusToObject(status);
    }
  }
}
</script>
<style>
.mismatchedStateReport .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
